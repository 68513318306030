import http from "./http";
import { removeAdminAuthen } from "../common/storage";
import message from "../common/message";

class DataService {
    _resolvePromise(httpMethod) {
        return new Promise((resolve) => {
            httpMethod
                .then(function(response) {
                    if (response && response.data) resolve(response.data);
                    else resolve(null);
                }).catch(function(error) {
                    console.log("error", error);
                    if (error.response && error.response.status == 401) {
                        removeAdminAuthen();
                        location.reload();
                        return resolve(null);
                    }
                    if (error.response && error.response.data && error.response.data.error) {
                        for (const key in message) {
                            if (Object.hasOwnProperty.call(message, key)) {
                                const element = message[key];
                                if (element.en == error.response.data.error)
                                    return resolve({ error: element.vn });
                            }
                        }
                        return resolve({ error: error.response.data.error });
                    }
                    return resolve(null);
                });
        });
    }
    put(url, data) {
        return this._resolvePromise(http.put(url, data));
    }
    post(url, data) {
        return this._resolvePromise(http.post(url, data));
    }
    get(url) {
        return this._resolvePromise(http.get(url));
    }
    delete(url) {
        return this._resolvePromise(http.delete(url));
    }
}

export default new DataService();