import axios from "axios";
import config from "../common/config";
import { getAdminAuthen } from "../common/storage";

const header = { Accept: "application/json" };
const user = getAdminAuthen();
if (user && user.token && user.username) {
    header.Admin = user.username;
    header.Token = user.token;
}

export default axios.create({
    baseURL: config.BASE_URL_SERVER,
    headers: header
});